// 生物医药
// 海洋生物与医药
export default [
    {
        id: "215",
        CompanyName: "深圳市鸿海水族设备有限公司",
        Link: "https://assets.vrnewg.com/215/html/index.html",
    },
    {
        id: "216",
        CompanyName: "汕尾市五丰海洋生物科技有限公司",
        Link: "https://assets.vrnewg.com/216/html/index.html",
    },
    {
        id: "217",
        CompanyName: "海南金鳄海洋生物科技有限公司",
        Link: "https://assets.vrnewg.com/217/html/index.html",
    },
    {
        id: "218",
        CompanyName: "北京史河科技有限公司",
        Link: "https://assets.vrnewg.com/218/html/index.html",
    },
    {
        id: "219",
        CompanyName: "深圳科立盾科技有限公司",
        Link: "https://assets.vrnewg.com/219/html/index.html",
    },
    {
        id: "445",
        CompanyName: "自然资源部第三海洋研究所",
        Link: "https://assets.vrnewg.com/445/html/index.html",
    },
    {
        id: "221",
        CompanyName: "厦门屿点海洋科技有限公司",
        Link: "https://assets.vrnewg.com/221/html/index.html",
    },
    {
        id: "222",
        CompanyName: "维度防爆工具（天津）集团有限公司",
        Link: "https://assets.vrnewg.com/222/html/index.html",
    },
    {
        id: "223",
        CompanyName: "杭州轻寻",
        Link: "https://assets.vrnewg.com/223/html/index.html",
    },
    {
        id: "224",
        CompanyName: "德州蓝力生物技术有限公司",
        Link: "https://assets.vrnewg.com/224/html/index.html",
    },
    {
        id: "290",
        CompanyName: "安徽陶博士环保科技有限公司",
        Link: "https://assets.vrnewg.com/290/html/index.html",
    },
    {
        id: "296",
        CompanyName: "北京玖天气象科技有限公司",
        Link: "https://assets.vrnewg.com/296/html/index.html",
    },
    {
        id: "324",
        CompanyName: "华测检测认证集团股份有限公司",
        Link: "https://assets.vrnewg.com/324/html/index.html",
    },
    {
        id: "331",
        CompanyName: "洛阳威尔若普检测技术有限公司",
        Link: "https://assets.vrnewg.com/331/html/index.html",
    },
    {
        id: "348",
        CompanyName: "泉州索爱膜科技开发有限公司",
        Link: "https://assets.vrnewg.com/348/html/index.html",
    },
    {
        id: "360",
        CompanyName: "上海溱道环保技术有限公司",
        Link: "https://assets.vrnewg.com/360/html/index.html",
    },
    {
        id: "368",
        CompanyName: "深圳极创机器人科技有限公司",
        Link: "https://assets.vrnewg.com/368/html/index.html",
    },
    {
        id: "400",
        CompanyName: "浙江通明电器股份有限公司",
        Link: "https://assets.vrnewg.com/400/html/index.html",
    },
    {
        id: "409",
        CompanyName: "中山市凌达润滑油有限公司",
        Link: "https://assets.vrnewg.com/409/html/index.html",
    },
    {
        id: "440",
        CompanyName: "深圳华大海洋科技有限公司",
        Link: "https://assets.vrnewg.com/440/html/index.html",
    },
    {
        id: "470",
        CompanyName: "北海国发山川生物股份有限公司",
        Link: "https://assets.vrnewg.com/470/html/index.html",
    },
    {
        id: "475",
        CompanyName: "广西阿蚌丁生物科技有限公司",
        Link: "https://assets.vrnewg.com/475/html/index.html",
    },
    {
        id: "595",
        CompanyName: "深圳市海莳光科技有限公司",
        Link: "https://assets.vrnewg.com/595/html/index.html",
    },
    {
        id: "598",
        CompanyName: "深圳市绿源环保志愿者协会",
        Link: "https://assets.vrnewg.com/598/html/index.html",
    },
    {
        id: "668",
        CompanyName: "深圳海洋卫士环保科技有限公司",
        Link: "https://assets.vrnewg.com/668/html/index.html",
    },
    {
        id: "682",
        CompanyName: "发利工程技术（深圳）有限公司",
        Link: "https://assets.vrnewg.com/682/html/index.html",
    },
    {
        id: "684",
        CompanyName: "广州桓乐生态环境科技有限公司",
        Link: "https://assets.vrnewg.com/684/html/index.html",
    },
    {
        id: "699",
        CompanyName: "优龙（北京）重防腐涂料有限公司",
        Link: "https://assets.vrnewg.com/699/html/index.html",
    },
    {
        id: "644",
        CompanyName: "北京华夏光谷广电科技有限公司",
        Link: "https://assets.vrnewg.com/644/html/index.html",
    },
    {
        id: "660",
        CompanyName: "大连鸿峰生物科技有限公司",
        Link: "https://assets.vrnewg.com/660/html/index.html",
    },
    {
        id: "687",
        CompanyName: "灵动智能光学（杭州）有限公司",
        Link: "https://assets.vrnewg.com/687/html/index.html",
    },
    {
        id: "930",
        CompanyName: "临港新片区海洋生物医药科技创新型平台",
        Link: "https://assets.vrnewg.com/930/html/index.html",
    },
];
