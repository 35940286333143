// 高端设备
// 海洋工程和环保
export default [
    {
        id: "122",
        Num: "2",
        CompanyName: "深圳市恒驱电机股份有限公司",
        Link: "https://assets.vrnewg.com/122/html/index.html",
    },
    {
        id: "123",
        Num: "1",
        CompanyName: "天津普友机电设备股份有限公司",
        Link: "https://assets.vrnewg.com/123/html/index.html",
    },
    {
        id: "124",
        Num: "3",
        CompanyName: "南通象屿海洋装备有限责任公司",
        Link: "https://assets.vrnewg.com/124/html/index.html",
    },
    {
        id: "125",
        Num: "2",
        CompanyName: "山东阳谷巨龙新型材料有限公司",
        Link: "https://assets.vrnewg.com/125/html/index.html",
    },
    {
        id: "126",
        Num: "1",
        CompanyName: "贵州钢绳股份有限公司",
        Link: "https://assets.vrnewg.com/126/html/index.html",
    },
    {
        id: "127",
        Num: "4",
        CompanyName: "山东海洋盛景渔业科技有限公司",
        Link: "https://assets.vrnewg.com/127/html/index.html",
    },
    {
        id: "128",
        Num: "1",
        CompanyName: "青岛海洋工程水下设备检测有限公司",
        Link: "https://assets.vrnewg.com/128/html/index.html",
    },
    {
        id: "129",
        Num: "3",
        CompanyName: "深圳市德威胜潜水工程有限公司",
        Link: "https://assets.vrnewg.com/129/html/index.html",
    },
    {
        id: "267",
        Num: "1",
        CompanyName: "天津昊野科技有限公司",
        Link: "https://assets.vrnewg.com/267/html/index.html",
    },
    {
        id: "131",
        Num: "4",
        CompanyName: "利生国际集团有限公司",
        Link: "https://assets.vrnewg.com/131/html/index.html",
    },
    {
        id: "132",
        Num: "2",
        CompanyName: "深圳市前海深港现代服务业合作区管理局",
        Link: "https://assets.vrnewg.com/132/html/index.html",
    },
    {
        id: "133",
        Num: "2",
        CompanyName: "河北朗威石油装备有限公司",
        Link: "https://assets.vrnewg.com/133/html/index.html",
    },
    {
        id: "463",
        Num: "3",
        CompanyName: "上海领海挪华企业发展有限公司",
        Link: "https://assets.vrnewg.com/463/html/index.html",
    },
    {
        id: "135",
        Num: "2",
        CompanyName: "钛永星(厦门）绳缆科技有限公司",
        Link: "https://assets.vrnewg.com/135/html/index.html",
    },
    {
        id: "137",
        Num: "5",
        CompanyName: "西门子能源有限公司",
        Link: "https://assets.vrnewg.com/137/html/index.html",
    },
    {
        id: "138",
        Num: "2",
        CompanyName: "恒泰艾普（深圳）科技有限公司",
        Link: "https://assets.vrnewg.com/138/html/index.html",
    },
    {
        id: "139",
        Num: "1",
        CompanyName: "意德电控科技(上海)有限公司",
        Link: "https://assets.vrnewg.com/139/html/index.html",
    },
    {
        id: "140",
        Num: "2",
        CompanyName: "深圳德克密封技术有限公司",
        Link: "https://assets.vrnewg.com/140/html/index.html",
    },
    {
        id: "141",
        Num: "1",
        CompanyName: "深圳华夏盛科技有限公司",
        Link: "https://assets.vrnewg.com/141/html/index.html",
    },
    {
        id: "142",
        Num: "1",
        CompanyName: "博诺米阀门自动化（深圳）有限公司",
        Link: "https://assets.vrnewg.com/142/html/index.html",
    },
    {
        id: "143",
        Num: "1",
        CompanyName: "宁波纽帕得",
        Link: "https://assets.vrnewg.com/143/html/index.html",
    },
    {
        id: "144",
        Num: "1",
        CompanyName: "上海深湾能源科技有限公司",
        Link: "https://assets.vrnewg.com/144/html/index.html",
    },
    {
        id: "145",
        Num: "2",
        CompanyName: "南阳市龙升瑞科石油机械制造有限公司",
        Link: "https://assets.vrnewg.com/145/html/index.html",
    },
    {
        id: "146",
        Num: "4",
        CompanyName: "武汉翔明激光科技有限公司",
        Link: "https://assets.vrnewg.com/146/html/index.html",
    },
    {
        id: "147",
        Num: "1",
        CompanyName: "盖科流体控制技术（常州）有限公司",
        Link: "https://assets.vrnewg.com/147/html/index.html",
    },
    {
        id: "148",
        Num: "1",
        CompanyName: "天津市精诚高压泵制造有限责任公司",
        Link: "https://assets.vrnewg.com/148/html/index.html",
    },
    {
        id: "149",
        Num: "2",
        CompanyName: "湖南益格特钢科技有限公司",
        Link: "https://assets.vrnewg.com/149/html/index.html",
    },
    {
        id: "150",
        Num: "3",
        CompanyName: "江门市东鸿金属制造有限公司",
        Link: "https://assets.vrnewg.com/150/html/index.html",
    },
    {
        id: "151",
        Num: "2",
        CompanyName: "上海恒生电讯工程有限公司",
        Link: "https://assets.vrnewg.com/151/html/index.html",
    },
    {
        id: "152",
        Num: "1",
        CompanyName: "丹阳市海威电热合金有限公司",
        Link: "https://assets.vrnewg.com/152/html/index.html",
    },
    {
        id: "153",
        Num: "2",
        CompanyName: "深圳市锐深科技有限公司",
        Link: "https://assets.vrnewg.com/153/html/index.html",
    },
    {
        id: "154",
        Num: "1",
        CompanyName: "建峰索具有限公司",
        Link: "https://assets.vrnewg.com/154/html/index.html",
    },
    {
        id: "155",
        Num: "3",
        CompanyName: "石家庄陆克石油技术有限公司",
        Link: "https://assets.vrnewg.com/155/html/index.html",
    },
    {
        id: "156",
        Num: "2",
        CompanyName: "特瑞堡海洋工程（青岛）有限公司",
        Link: "https://assets.vrnewg.com/156/html/index.html",
    },
    {
        id: "157",
        Num: "2",
        CompanyName: "南海海缆有限公司",
        Link: "https://assets.vrnewg.com/157/html/index.html",
    },
    {
        id: "158",
        Num: "1",
        CompanyName: "中国直升机设计研究所",
        Link: "https://assets.vrnewg.com/158/html/index.html",
    },
    {
        id: "159",
        Num: "2",
        CompanyName: "上海圆舟电子科技有限公司",
        Link: "https://assets.vrnewg.com/159/html/index.html",
    },
    {
        id: "259",
        Num: "1",
        CompanyName: "青岛瑞泰尔电子技术有限公司",
        Link: "https://assets.vrnewg.com/259/html/index.html",
    },
    {
        id: "161",
        Num: "1",
        CompanyName: "天津飞眼无人机科技有限公司",
        Link: "https://assets.vrnewg.com/161/html/index.html",
    },
    {
        id: "162",
        Num: "1",
        CompanyName: "海达路德邮轮",
        Link: "https://assets.vrnewg.com/162/html/index.html",
    },
    {
        id: "163",
        Num: "1",
        CompanyName: "山东金润德新材料科技股份有限公司",
        Link: "https://assets.vrnewg.com/163/html/index.html",
    },
    {
        id: "164",
        Num: "5",
        CompanyName: "广西玉柴机器有限公司",
        Link: "https://assets.vrnewg.com/164/html/index.html",
    },
    {
        id: "165",
        Num: "1",
        CompanyName: "北京博科测试系统股份有限公司",
        Link: "https://assets.vrnewg.com/165/html/index.html",
    },
    {
        id: "166",
        Num: "3",
        CompanyName: "广东南风王科技有限公司",
        Link: "https://assets.vrnewg.com/166/html/index.html",
    },
    {
        id: "167",
        Num: "2",
        CompanyName: "深圳太平洋联合航空科技有限公司",
        Link: "https://assets.vrnewg.com/167/html/index.html",
    },
    {
        id: "168",
        Num: "2",
        CompanyName: "广州君圣液压设备制造有限公司",
        Link: "https://assets.vrnewg.com/168/html/index.html",
    },
    {
        id: "169",
        Num: "1",
        CompanyName: "湖南凯利制冷设备有限公司",
        Link: "https://assets.vrnewg.com/169/html/index.html",
    },
    {
        id: "170",
        Num: "2",
        CompanyName: "上海瑞卓软件股份有限公司",
        Link: "https://assets.vrnewg.com/170/html/index.html",
    },
    {
        id: "171",
        Num: "1",
        CompanyName: "握友智能科技（深圳）有限公司",
        Link: "https://assets.vrnewg.com/171/html/index.html",
    },
    {
        id: "172",
        Num: "1",
        CompanyName: "东莞市赛麦客运动用品有限公司",
        Link: "https://assets.vrnewg.com/172/html/index.html",
    },
    {
        id: "173",
        Num: "2",
        CompanyName: "深圳市行健自动化股份有限公司",
        Link: "https://assets.vrnewg.com/173/html/index.html",
    },
    {
        id: "174",
        Num: "5",
        CompanyName: "克拉尔（无锡）泵业有限公司",
        Link: "https://assets.vrnewg.com/174/html/index.html",
    },
    {
        id: "175",
        Num: "1",
        CompanyName: "如东宏信机械制造有限公司",
        Link: "https://assets.vrnewg.com/175/html/index.html",
    },
    {
        id: "176",
        Num: "2",
        CompanyName: "深圳市海基实业有限公司",
        Link: "https://assets.vrnewg.com/176/html/index.html",
    },
    {
        id: "177",
        Num: "2",
        CompanyName: "青岛光流软件技术有限公司",
        Link: "https://assets.vrnewg.com/177/html/index.html",
    },
    {
        id: "178",
        Num: "1",
        CompanyName: "上海单点海洋技术有限公司",
        Link: "https://assets.vrnewg.com/178/html/index.html",
    },
    {
        id: "179",
        Num: "4",
        CompanyName: "山东拓普液压气动有限公司",
        Link: "https://assets.vrnewg.com/179/html/index.html",
    },
    {
        id: "180",
        Num: "5",
        CompanyName: "中集来福士",
        Link: "https://assets.vrnewg.com/180/html/index.html",
    },
    {
        id: "181",
        Num: "2",
        CompanyName: "兰德舶格",
        Link: "https://assets.vrnewg.com/181/html/index.html",
    },
    {
        id: "182",
        Num: "2",
        CompanyName: "青岛万育堂大药房有限公司",
        Link: "https://assets.vrnewg.com/182/html/index.html",
    },
    {
        id: "260",
        Num: "2",
        CompanyName: "西安雷声泰达水声设备有限责任公司",
        Link: "https://assets.vrnewg.com/260/html/index.html",
    },
    {
        id: "265",
        Num: "2",
        CompanyName: "中山市声诺仪器设备有限公司",
        Link: "https://assets.vrnewg.com/265/html/index.html",
    },
    {
        id: "269",
        Num: "1",
        CompanyName: "云南保利天同水下装备科技有限公司",
        Link: "https://assets.vrnewg.com/269/html/index.html",
    },
    {
        id: "292",
        Num: "7",
        CompanyName: "宝鸡兄弟联合金属工业有限公司",
        Link: "https://assets.vrnewg.com/292/html/index.html",
    },
    {
        id: "299",
        Num: "7",
        CompanyName: "北维电机有限公司",
        Link: "https://assets.vrnewg.com/299/html/index.html",
    },
    {
        id: "300",
        Num: "2",
        CompanyName: "常州一粟水下机器人科技有限公司",
        Link: "https://assets.vrnewg.com/300/html/index.html",
    },
    {
        id: "301",
        Num: "1",
        CompanyName: "慈溪市君益塑业有限公司",
        Link: "https://assets.vrnewg.com/301/html/index.html",
    },
    {
        id: "303",
        Num: "2",
        CompanyName: "缔柯圣（上海）贸易有限公司",
        Link: "https://assets.vrnewg.com/303/html/index.html",
    },
    {
        id: "306",
        Num: "6",
        CompanyName: "东莞市沃丰流体科技有限公司",
        Link: "https://assets.vrnewg.com/306/html/index.html",
    },
    {
        id: "312",
        Num: "3",
        CompanyName: "广州打捞局",
        Link: "https://assets.vrnewg.com/312/html/index.html",
    },
    {
        id: "430",
        Num: "14",
        CompanyName: "广州海豹光电科技有限公司",
        Link: "https://assets.vrnewg.com/430/html/index.html",
    },
    {
        id: "315",
        Num: "2",
        CompanyName: "广州南方卫星导航仪器有限公司",
        Link: "https://assets.vrnewg.com/315/html/index.html",
    },
    {
        id: "316",
        Num: "9",
        CompanyName: "广州派晟工业技术有限公司",
        Link: "https://assets.vrnewg.com/316/html/index.html",
    },
    {
        id: "317",
        Num: "3",
        CompanyName: "广州市净宇科学仪器有限公司",
        Link: "https://assets.vrnewg.com/317/html/index.html",
    },
    {
        id: "322",
        Num: "7",
        CompanyName: "河北新时基业防火保温材料制造有限公司",
        Link: "https://assets.vrnewg.com/322/html/index.html",
    },
    {
        id: "323",
        Num: "8",
        CompanyName: "湖南耐普泵业股份有限公司",
        Link: "https://assets.vrnewg.com/323/html/index.html",
    },
    {
        id: "325",
        Num: "7",
        CompanyName: "嘉善迪克精密机械有限公司",
        Link: "https://assets.vrnewg.com/325/html/index.html",
    },
    {
        id: "326",
        Num: "7",
        CompanyName: "克雷登热能设备（浙江）有限公司",
        Link: "https://assets.vrnewg.com/326/html/index.html",
    },
    {
        id: "327",
        Num: "7",
        CompanyName: "立格科技（广州）有限公司",
        Link: "https://assets.vrnewg.com/327/html/index.html",
    },
    {
        id: "328",
        Num: "7",
        CompanyName: "利丰海洋工程（天津）有限公司",
        Link: "https://assets.vrnewg.com/328/html/index.html",
    },
    {
        id: "329",
        Num: "8",
        CompanyName: "洛阳百克特科技发展股份有限公司",
        Link: "https://assets.vrnewg.com/329/html/index.html",
    },
    {
        id: "330",
        Num: "7",
        CompanyName: "洛阳圣瑞智能机器人有限公司",
        Link: "https://assets.vrnewg.com/330/html/index.html",
    },
    {
        id: "332",
        Num: "6",
        CompanyName: "迈思可工业技术（上海）有限公司",
        Link: "https://assets.vrnewg.com/332/html/index.html",
    },
    {
        id: "333",
        Num: "1",
        CompanyName: "麦格思维特（上海）流体工程有限公司",
        Link: "https://assets.vrnewg.com/333/html/index.html",
    },
    {
        id: "334",
        Num: "9",
        CompanyName: "曼太柯（杭州）流体技术有限公司",
        Link: "https://assets.vrnewg.com/334/html/index.html",
    },
    {
        id: "335",
        Num: "7",
        CompanyName: "绵阳市腾扬机电制品有限责任公司",
        Link: "https://assets.vrnewg.com/335/html/index.html",
    },
    {
        id: "337",
        Num: "1",
        CompanyName: "宁波浮田生物技术有限公司",
        Link: "https://assets.vrnewg.com/337/html/index.html",
    },
    {
        id: "338",
        Num: "7",
        CompanyName: "宁波迈巴赫工程塑料有限公司",
        Link: "https://assets.vrnewg.com/338/html/index.html",
    },
    {
        id: "339",
        Num: "7",
        CompanyName: "宁波獅球通风机电有限公司",
        Link: "https://assets.vrnewg.com/339/html/index.html",
    },
    {
        id: "344",
        Num: "7",
        CompanyName: "青岛海洋新材料科技有限公司",
        Link: "https://assets.vrnewg.com/344/html/index.html",
    },
    {
        id: "345",
        Num: "8",
        CompanyName: "青岛炬荣工程科技有限公司",
        Link: "https://assets.vrnewg.com/345/html/index.html",
    },
    {
        id: "351",
        Num: "9",
        CompanyName: "三亚崖州湾科技城管理局",
        Link: "https://assets.vrnewg.com/351/html/index.html",
    },
    {
        id: "355",
        Num: "3",
        CompanyName: "上海彩虹鱼海洋科技股份有限公司",
        Link: "https://assets.vrnewg.com/355/html/index.html",
    },
    {
        id: "361",
        Num: "7",
        CompanyName: "上海伊勉特液压器材有限公司",
        Link: "https://assets.vrnewg.com/361/html/index.html",
    },
    {
        id: "375",
        Num: "6",
        CompanyName: "深圳市库马克新技术股份有限公司",
        Link: "https://assets.vrnewg.com/375/html/index.html",
    },
    {
        id: "377",
        Num: "7",
        CompanyName: "深圳市联欧贸易发展有限公司",
        Link: "https://assets.vrnewg.com/377/html/index.html",
    },
    {
        id: "378",
        Num: "2",
        CompanyName: "深圳市摩控自动化设备有限公司",
        Link: "https://assets.vrnewg.com/378/html/index.html",
    },
    {
        id: "379",
        Num: "7",
        CompanyName: "深圳市天泉空气水智能科技股份有限公司",
        Link: "https://assets.vrnewg.com/379/html/index.html",
    },
    {
        id: "383",
        Num: "7",
        CompanyName: "苏州福来西垫片技术有限公司",
        Link: "https://assets.vrnewg.com/383/html/index.html",
    },
    {
        id: "385",
        Num: "7",
        CompanyName: "天津瑞特海洋工程有限公司",
        Link: "https://assets.vrnewg.com/385/html/index.html",
    },
    {
        id: "389",
        Num: "7",
        CompanyName: "西安泰迪机电工程有限公司",
        Link: "https://assets.vrnewg.com/389/html/index.html",
    },
    {
        id: "391",
        Num: "7",
        CompanyName: "兴宁市奥浦合金工业有限公司",
        Link: "https://assets.vrnewg.com/391/html/index.html",
    },
    {
        id: "395",
        Num: "7",
        CompanyName: "云南保利天同仪器有限公司",
        Link: "https://assets.vrnewg.com/395/html/index.html",
    },
    {
        id: "397",
        Num: "7",
        CompanyName: "浙江青山钢管有限公司",
        Link: "https://assets.vrnewg.com/397/html/index.html",
    },
    {
        id: "399",
        Num: "6",
        CompanyName: "浙江松乔气动液压有限公司",
        Link: "https://assets.vrnewg.com/399/html/index.html",
    },
    {
        id: "402",
        Num: "7",
        CompanyName: "浙江鱼童新材料股份有限公司",
        Link: "https://assets.vrnewg.com/402/html/index.html",
    },
    {
        id: "406",
        Num: "7",
        CompanyName: "中海油常州涂料化工研究院有限公司",
        Link: "https://assets.vrnewg.com/406/html/index.html",
    },
    {
        id: "412",
        Num: "7",
        CompanyName: "珠海德赛海洋渔业科技有限公司",
        Link: "https://assets.vrnewg.com/412/html/index.html",
    },
    {
        id: "415",
        Num: "7",
        CompanyName: "自贡市精强硬质合金有限公司",
        Link: "https://assets.vrnewg.com/415/html/index.html",
    },
    {
        id: "430",
        Num: "14",
        CompanyName: "广州海豹光电科技有限公司",
        Link: "https://assets.vrnewg.com/430/html/index.html",
    },
    {
        id: "432",
        Num: "11",
        CompanyName: "杭州腾海科技有限公司",
        Link: "https://assets.vrnewg.com/432/html/index.html",
    },
    {
        id: "442",
        Num: "9",
        CompanyName: "三亚崖州湾科技城管理局、招商三亚深海科技城开发有限公司",
        Link: "https://assets.vrnewg.com/442/html/index.html",
    },
    {
        id: "451",
        Num: "15",
        CompanyName: "深圳市柯耐特科技有限公司",
        Link: "https://assets.vrnewg.com/451/html/index.html",
    },
    {
        id: "455",
        Num: "9",
        CompanyName: "浙江凯富博科科技有限公司",
        Link: "https://assets.vrnewg.com/455/html/index.html",
    },
    {
        id: "466",
        Num: "16",
        CompanyName: "广西北部湾国际港务集团有限公司",
        Link: "https://assets.vrnewg.com/466/html/index.html",
    },
    {
        id: "467",
        Num: "3",
        CompanyName: "北海星天科技有限公司公司",
        Link: "https://assets.vrnewg.com/467/html/index.html",
    },
    {
        id: "468",
        Num: "8",
        CompanyName: "广西玉柴机器集团有限公司",
        Link: "https://assets.vrnewg.com/468/html/index.html",
    },
    {
        id: "469",
        Num: "10",
        CompanyName: "中船广西船舶及海洋工程有限公司",
        Link: "https://assets.vrnewg.com/469/html/index.html",
    },
    {
        id: "471",
        Num: "14",
        CompanyName: "北海黑珍珠海洋生物科技有限公司",
        Link: "https://assets.vrnewg.com/471/html/index.html",
    },
    {
        id: "472",
        Num: "17",
        CompanyName: "北海市兴龙生物制品有限公司",
        Link: "https://assets.vrnewg.com/472/html/index.html",
    },
    {
        id: "473",
        Num: "14",
        CompanyName: "广西小藻农业科技有限公司",
        Link: "https://assets.vrnewg.com/473/html/index.html",
    },
    {
        id: "474",
        Num: "12",
        CompanyName: "北海市源龙珍珠有限公司",
        Link: "https://assets.vrnewg.com/474/html/index.html",
    },
    {
        id: "476",
        Num: "8",
        CompanyName: "广西海世通食品股份有限公司",
        Link: "https://assets.vrnewg.com/476/html/index.html",
    },
    {
        id: "477",
        Num: "2",
        CompanyName: "安雷流体科技（深圳）有限公司",
        Link: "https://assets.vrnewg.com/477/html/index.html",
    },
    {
        id: "479",
        Num: "7",
        CompanyName: "北京培瓦克机械有限公司",
        Link: "https://assets.vrnewg.com/479/html/index.html",
    },
    {
        id: "486",
        Num: "7",
        CompanyName: "海洋化工研究院有限公司",
        Link: "https://assets.vrnewg.com/486/html/index.html",
    },
    {
        id: "488",
        Num: "7",
        CompanyName: "杭州钒洛科技有限公司",
        Link: "https://assets.vrnewg.com/488/html/index.html",
    },
    {
        id: "492",
        Num: "2",
        CompanyName: "江苏亚星锚链股份有限公司",
        Link: "https://assets.vrnewg.com/492/html/index.html",
    },
    {
        id: "493",
        Num: "7",
        CompanyName: "江苏兆胜空调有限公司",
        Link: "https://assets.vrnewg.com/493/html/index.html",
    },
    {
        id: "496",
        Num: "6",
        CompanyName: "上海精卫电子有限公司",
        Link: "https://assets.vrnewg.com/496/html/index.html",
    },
    {
        id: "497",
        Num: "7",
        CompanyName: "青岛固瑞格新材料科技有限公司",
        Link: "https://assets.vrnewg.com/497/html/index.html",
    },
    {
        id: "498",
        Num: "7",
        CompanyName: "山东鲁盾聚氨酯制品有限公司",
        Link: "https://assets.vrnewg.com/498/html/index.html",
    },
    {
        id: "502",
        Num: "3",
        CompanyName: "锡瑞迪船用动力系统（上海）有限公司",
        Link: "https://assets.vrnewg.com/502/html/index.html",
    },
    {
        id: "505",
        Num: "2",
        CompanyName: "上海ABB工程有限公司",
        Link: "https://assets.vrnewg.com/505/html/index.html",
    },
    {
        id: "507",
        Num: "7",
        CompanyName: "青岛和洋新材料有限公司",
        Link: "https://assets.vrnewg.com/507/html/index.html",
    },
    {
        id: "515",
        Num: "2",
        CompanyName: "深圳市发利构件机械技术服务有限公司",
        Link: "https://assets.vrnewg.com/515/html/index.html",
    },
    {
        id: "527",
        Num: "7",
        CompanyName: "乌克兰曙光-机械设计科研生产联合体",
        Link: "https://assets.vrnewg.com/527/html/index.html",
    },
    {
        id: "529",
        Num: "7",
        CompanyName: "张家港市太平洋泵业制造有限公司",
        Link: "https://assets.vrnewg.com/529/html/index.html",
    },
    {
        id: "551",
        Num: "7",
        CompanyName: "巴音布鲁克工程技术（广东）有限公司",
        Link: "https://assets.vrnewg.com/551/html/index.html",
    },
    {
        id: "553",
        Num: "7",
        CompanyName: "宝鸡市新福泉机械科技有限公司",
        Link: "https://assets.vrnewg.com/553/html/index.html",
    },
    {
        id: "557",
        Num: "7",
        CompanyName: "德庆县奥众风机设备有限公司",
        Link: "https://assets.vrnewg.com/557/html/index.html",
    },
    {
        id: "570",
        Num: "7",
        CompanyName: "杭州瀚陆海洋科技有限公司",
        Link: "https://assets.vrnewg.com/570/html/index.html",
    },
    {
        id: "572",
        Num: "7",
        CompanyName: "惠州碧海源科技有限公司",
        Link: "https://assets.vrnewg.com/572/html/index.html",
    },
    {
        id: "577",
        Num: "7",
        CompanyName: "江苏西沙科技有限公司",
        Link: "https://assets.vrnewg.com/577/html/index.html",
    },
    {
        id: "579",
        Num: "7",
        CompanyName: "雷勃电气（无锡）有限公司",
        Link: "https://assets.vrnewg.com/579/html/index.html",
    },
    {
        id: "581",
        Num: "7",
        CompanyName: "南通力驰复合材料有限公司",
        Link: "https://assets.vrnewg.com/581/html/index.html",
    },
    {
        id: "584",
        Num: "7",
        CompanyName: "青岛昌辉管业有限公司",
        Link: "https://assets.vrnewg.com/584/html/index.html",
    },
    {
        id: "589",
        Num: "7",
        CompanyName: "上海长兴海洋装备产业基地开发有限公司",
        Link: "https://assets.vrnewg.com/589/html/index.html",
    },
    {
        id: "592",
        Num: "7",
        CompanyName: "深圳市爱尔特阀门有限公司",
        Link: "https://assets.vrnewg.com/592/html/index.html",
    },
    {
        id: "597",
        Num: "7",
        CompanyName: "深圳市晶沛电子有限公司",
        Link: "https://assets.vrnewg.com/597/html/index.html",
    },
    {
        id: "600",
        Num: "7",
        CompanyName: "深圳市万得连接技术有限公司",
        Link: "https://assets.vrnewg.com/600/html/index.html",
    },
    {
        id: "606",
        Num: "2",
        CompanyName: "苏州福沃得安全设备有限公司",
        Link: "https://assets.vrnewg.com/606/html/index.html",
    },
    {
        id: "610",
        Num: "7",
        CompanyName: "韬鑫金属科技（苏州）有限公司",
        Link: "https://assets.vrnewg.com/610/html/index.html",
    },
    {
        id: "612",
        Num: "2",
        CompanyName: "天津市通洁高压泵制造有限公司",
        Link: "https://assets.vrnewg.com/612/html/index.html",
    },
    {
        id: "613",
        Num: "7",
        CompanyName: "无锡达古金属材料有限公司",
        Link: "https://assets.vrnewg.com/613/html/index.html",
    },
    {
        id: "615",
        Num: "7",
        CompanyName: "徐州新兴达克罗科技有限公司",
        Link: "https://assets.vrnewg.com/615/html/index.html",
    },
    {
        id: "617",
        Num: "7",
        CompanyName: "浙江深孚新材料科技有限公司",
        Link: "https://assets.vrnewg.com/617/html/index.html",
    },
    {
        id: "618",
        Num: "7",
        CompanyName: "中挪未来创新科技（青岛）有限公司",
        Link: "https://assets.vrnewg.com/618/html/index.html",
    },
    {
        id: "622",
        Num: "7",
        CompanyName: "青岛泰戈菲斯海洋装备股份公司",
        Link: "https://assets.vrnewg.com/622/html/index.html",
    },
    {
        id: "646",
        Num: "7",
        CompanyName: "广东麦克斯泰新材料有限公司",
        Link: "https://assets.vrnewg.com/646/html/index.html",
    },
    {
        id: "650",
        Num: "7",
        CompanyName: "深圳市环宇通电机维修有限公司",
        Link: "https://assets.vrnewg.com/650/html/index.html",
    },
    {
        id: "651",
        Num: "7",
        CompanyName: "深圳市铭镭激光设备有限公司",
        Link: "https://assets.vrnewg.com/651/html/index.html",
    },
    {
        id: "652",
        Num: "7",
        CompanyName: "深圳市瑞能电机技术有限公司",
        Link: "https://assets.vrnewg.com/652/html/index.html",
    },
    {
        id: "656",
        Num: "7",
        CompanyName: "中国南山开发（集团）股份有限公司",
        Link: "https://assets.vrnewg.com/656/html/index.html",
    },
    {
        id: "657",
        Num: "7",
        CompanyName: "中科技术物理苏州研究院",
        Link: "https://assets.vrnewg.com/657/html/index.html",
    },
    {
        id: "659",
        Num: "7",
        CompanyName: "宝鸡拓普达钛业有限公司",
        Link: "https://assets.vrnewg.com/659/html/index.html",
    },
    {
        id: "662",
        Num: "7",
        CompanyName: "杭州海斗量海洋仪器有限公司",
        Link: "https://assets.vrnewg.com/662/html/index.html",
    },
    {
        id: "664",
        Num: "7",
        CompanyName: "宁波博威合金材料股份有限公司",
        Link: "https://assets.vrnewg.com/664/html/index.html",
    },
    {
        id: "666",
        Num: "7",
        CompanyName: "上海宝亚安全装备股份有限公司",
        Link: "https://assets.vrnewg.com/666/html/index.html",
    },
    {
        id: "673",
        Num: "7",
        CompanyName: "深圳市亿杰仪表有限公司",
        Link: "https://assets.vrnewg.com/673/html/index.html",
    },
    {
        id: "676",
        Num: "7",
        CompanyName: "中建科技集团有限公司",
        Link: "https://assets.vrnewg.com/676/html/index.html",
    },
    {
        id: "678",
        Num: "7",
        CompanyName: "北京航天绘景科技有限公司",
        Link: "https://assets.vrnewg.com/678/html/index.html",
    },
    {
        id: "692",
        Num: "7",
        CompanyName: "深圳市思特克气动液压有限公司",
        Link: "https://assets.vrnewg.com/692/html/index.html",
    },

    {
        id: 702,
        CompanyName: "马力钢丸科技有限公司",
        Link: "https://assets.vrnewg.com/702/html/index.html",
    },
    {
        id: 705,
        CompanyName: "上海临港海洋高新技术产业发展有限公司",
        Link: "https://assets.vrnewg.com/705/html/index.html",
    },
    {
        id: 706,
        CompanyName: "山东天洋新材料有限公司",
        Link: "https://assets.vrnewg.com/706/html/index.html",
    },
    {
        id: 713,
        CompanyName: "佛山市蓝色星科技有限公司",
        Link: "https://assets.vrnewg.com/713/html/index.html",
    },
    {
        id: 714,
        CompanyName: "广州凌杰流体科技有限公司",
        Link: "https://assets.vrnewg.com/714/html/index.html",
    },
    {
        id: 717,
        CompanyName: "易俐特自动化技术股份有限公司",
        Link: "https://assets.vrnewg.com/717/html/index.html",
    },
    {
        id: 721,
        CompanyName: "青岛华凯海洋科技有限公司",
        Link: "https://assets.vrnewg.com/721/html/index.html",
    },
    {
        id: 724,
        CompanyName: "广东省惠湾建安工程建设有限公司",
        Link: "https://assets.vrnewg.com/724/html/index.html",
    },
    {
        id: 726,
        CompanyName: "上海恒生浩盛海洋科技有限公司",
        Link: "https://assets.vrnewg.com/726/html/index.html",
    },
    {
        id: 733,
        CompanyName: "上海蓝创海洋科技有限公司",
        Link: "https://assets.vrnewg.com/733/html/index.html",
    },
    {
        id: 735,
        CompanyName: "穆特科技(武汉) 股份有限公司",
        Link: "https://assets.vrnewg.com/735/html/index.html",
    },
    {
        id: 742,
        CompanyName: "伊特船舶国际有限公司",
        Link: "https://assets.vrnewg.com/742/html/index.html",
    },
    {
        id: 743,
        CompanyName: "深圳市优贝特科技有限公司",
        Link: "https://assets.vrnewg.com/743/html/index.html",
    },
    {
        id: 744,
        CompanyName: "上海恒晟永信海洋科技有限公司",
        Link: "https://assets.vrnewg.com/744/html/index.html",
    },
    {
        id: 759,
        CompanyName: "乾鼎（北京）科技有限责任公司",
        Link: "https://assets.vrnewg.com/759/html/index.html",
    },
    {
        id: 760,
        CompanyName: "广州瑞港消防设备有限公司",
        Link: "https://assets.vrnewg.com/760/html/index.html",
    },
    {
        id: 774,
        CompanyName: "北京国冶锐诚工程技术有限公司",
        Link: "https://assets.vrnewg.com/774/html/index.html",
    },
    {
        id: 841,
        CompanyName: "加拿大凯顿国际公司北京代表处",
        Link: "https://assets.vrnewg.com/841/html/index.html",
    },
    {
        id: 842,
        CompanyName: "深圳市冠宇电器科技有限公司",
        Link: "https://assets.vrnewg.com/842/html/index.html",
    },
    {
        id: 847,
        CompanyName: "自然资源部天津海水淡化与综合利用研究所",
        Link: "https://assets.vrnewg.com/847/html/index.html",
    },
    {
        id: 850,
        CompanyName: "捷意分析技术(杭州)有限公司",
        Link: "https://assets.vrnewg.com/850/html/index.html",
    },
    {
        id: 855,
        CompanyName: "广州耀海科技有限公司",
        Link: "https://assets.vrnewg.com/855/html/index.html",
    },
    {
        id: 856,
        CompanyName: "江门市正盛重工有限公司",
        Link: "https://assets.vrnewg.com/856/html/index.html",
    },
    {
        id: 860,
        CompanyName: "深圳市无疆海洋科技有限公司",
        Link: "https://assets.vrnewg.com/860/html/index.html",
    },
    {
        id: 863,
        CompanyName: "深圳市南星海洋工程服务有限公司",
        Link: "https://assets.vrnewg.com/863/html/index.html",
    },
    {
        id: 867,
        CompanyName: "台州老根金属制品有限公司",
        Link: "https://assets.vrnewg.com/867/html/index.html",
    },
    {
        id: 869,
        CompanyName: "东莞市华乐密封技术开发有限公司",
        Link: "https://assets.vrnewg.com/869/html/index.html",
    },
    {
        id: 870,
        CompanyName: "北京百力能安全防护设备有限责任公司",
        Link: "https://assets.vrnewg.com/870/html/index.html",
    },
    {
        id: 886,
        CompanyName: "翱飞（无锡）科技有限公司",
        Link: "https://assets.vrnewg.com/886/html/index.html",
    },
    {
        id: 887,
        CompanyName: "青岛玛瑞泰科科技有限公司",
        Link: "https://assets.vrnewg.com/887/html/index.html",
    },
    {
        id: 888,
        CompanyName: "谱尼测试集团深圳有限公司",
        Link: "https://assets.vrnewg.com/888/html/index.html",
    },
    {
        id: 889,
        CompanyName: "奥意建筑工程设计有限公司",
        Link: "https://assets.vrnewg.com/889/html/index.html",
    },
    {
        id: 892,
        CompanyName: "滨州恒丰化纤制品有限公司",
        Link: "https://assets.vrnewg.com/892/html/index.html",
    },
    {
        id: 905,
        CompanyName: "恩平市亿丰塑料模具有限公司",
        Link: "https://assets.vrnewg.com/905/html/index.html",
    },
    {
        id: 906,
        CompanyName: "华盛丰生态（深圳）有限公司",
        Link: "https://assets.vrnewg.com/906/html/index.html",
    },
    {
        id: 909,
        CompanyName: "百勤能源科技（广东）股份有限公司",
        Link: "https://assets.vrnewg.com/909/html/index.html",
    },
    {
        id: 920,
        CompanyName: "广州德莱克自动化设备股份有限公司",
        Link: "https://assets.vrnewg.com/920/html/index.html",
    },
    {
        id: 948,
        CompanyName: "深圳投石产业科技有限公司",
        Link: "https://assets.vrnewg.com/948/html/index.html",
    },
    {
        id: 949,
        CompanyName: "深圳纵横无人机科技有限公司",
        Link: "https://assets.vrnewg.com/949/html/index.html",
    },
];
