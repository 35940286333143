// 海洋船舶及港口航运
// 海洋港口与航运
export default [
    {
        id: "55",
        CompanyName: "广州运华机电有限公司",
        Link: "https://assets.vrnewg.com/55/html/index.html",
    },
    {
        id: "56",
        CompanyName: "青岛奥海特船舶有限公司",
        Link: "https://assets.vrnewg.com/56/html/index.html",
    },
    {
        id: "57",
        CompanyName: "中国化工信息中心有限公司",
        Link: "https://assets.vrnewg.com/57/html/index.html",
    },
    {
        id: "58",
        CompanyName: "合德海洋科技（大连）有限公司",
        Link: "https://assets.vrnewg.com/58/html/index.html",
    },
    {
        id: "59",
        CompanyName: "深圳市帝思达电子有限公司",
        Link: "https://assets.vrnewg.com/59/html/index.html",
    },
    {
        id: "60",
        CompanyName: "舜驰实业（东莞）有限公司",
        Link: "https://assets.vrnewg.com/60/html/index.html",
    },
    {
        id: "61",
        CompanyName: "意大利船级社（中国）有限公司",
        Link: "https://assets.vrnewg.com/61/html/index.html",
    },
    {
        id: "62",
        CompanyName: "海斯比海洋科技",
        Link: "https://assets.vrnewg.com/62/html/index.html",
    },
    {
        id: "434",
        CompanyName: "广东逸动科技有限公司",
        Link: "https://assets.vrnewg.com/434/html/index.html",
    },
    {
        id: "64",
        CompanyName: "江门中天一正机电有限公司",
        Link: "https://assets.vrnewg.com/64/html/index.html",
    },
    {
        id: "65",
        CompanyName: "深圳市金石铭光电有限公司",
        Link: "https://assets.vrnewg.com/65/html/index.html",
    },
    {
        id: "66",
        CompanyName: "浙江傲宋智能科技有限公司",
        Link: "https://assets.vrnewg.com/66/html/index.html",
    },
    {
        id: "67",
        CompanyName: "威海蓝博海洋装备有限公司",
        Link: "https://assets.vrnewg.com/67/html/index.html",
    },
    {
        id: "68",
        CompanyName: "恩蒂埃建筑设计咨询（上海）有限公司",
        Link: "https://assets.vrnewg.com/68/html/index.html",
    },
    {
        id: "69",
        CompanyName: "青岛船长协会",
        Link: "https://assets.vrnewg.com/69/html/index.html",
    },
    {
        id: "70",
        CompanyName: "星展测控科技股份有限公司",
        Link: "https://assets.vrnewg.com/70/html/index.html",
    },
    {
        id: "71",
        CompanyName: "深圳市顺航通导科技有限公司",
        Link: "https://assets.vrnewg.com/71/html/index.html",
    },
    {
        id: "72",
        CompanyName: "福格申机械工程（昆山）有限公司",
        Link: "https://assets.vrnewg.com/72/html/index.html",
    },
    {
        id: "73",
        CompanyName: "国通净美科技服务有限公司",
        Link: "https://assets.vrnewg.com/73/html/index.html",
    },
    {
        id: "74",
        CompanyName: "通标标准技术服务有限公司深圳分公",
        Link: "https://assets.vrnewg.com/74/html/index.html",
    },
    {
        id: "75",
        CompanyName: "浙江冰力格机电有限公司",
        Link: "https://assets.vrnewg.com/75/html/index.html",
    },
    {
        id: "76",
        CompanyName: "盖雷流体技术（广州）有限公司",
        Link: "https://assets.vrnewg.com/76/html/index.html",
    },
    {
        id: "77",
        CompanyName: "杭州雅格纳科技有限公司",
        Link: "https://assets.vrnewg.com/77/html/index.html",
    },
    {
        id: "78",
        CompanyName: "中国船舶重工集团有限公司第七一0研究所",
        Link: "https://assets.vrnewg.com/78/html/index.html",
    },
    {
        id: "79",
        CompanyName: "神逸（沈阳）特种涂料销售有限公司",
        Link: "https://assets.vrnewg.com/79/html/index.html",
    },
    {
        id: "80",
        CompanyName: "蓬莱中柏京鲁船业有限公司",
        Link: "https://assets.vrnewg.com/80/html/index.html",
    },
    {
        id: "81",
        CompanyName: "深圳万用房屋智造科技有限公司",
        Link: "https://assets.vrnewg.com/81/html/index.html",
    },
    {
        id: "82",
        CompanyName: "江苏池九科技有限公司",
        Link: "https://assets.vrnewg.com/82/html/index.html",
    },
    {
        id: "83",
        CompanyName: "浙江汇弘机械有限公司",
        Link: "https://assets.vrnewg.com/83/html/index.html",
    },
    {
        id: "84",
        CompanyName: "浙江横海科技有限公司",
        Link: "https://assets.vrnewg.com/84/html/index.html",
    },
    {
        id: "85",
        CompanyName: "青岛新盛交通设施有限公司",
        Link: "https://assets.vrnewg.com/85/html/index.html",
    },
    {
        id: "86",
        CompanyName: "上海芳鹏合金材料有限公司",
        Link: "https://assets.vrnewg.com/86/html/index.html",
    },
    {
        id: "87",
        CompanyName: "珠海市威旗防腐科技股份有限公司",
        Link: "https://assets.vrnewg.com/87/html/index.html",
    },
    {
        id: "88",
        CompanyName: "广州科勒尔制冷设备有限公司",
        Link: "https://assets.vrnewg.com/88/html/index.html",
    },
    {
        id: "89",
        CompanyName: "深圳市盐田港集团有限公司",
        Link: "https://assets.vrnewg.com/89/html/index.html",
    },
    {
        id: "90",
        CompanyName: "淮安市振达钢管制造有限公司",
        Link: "https://assets.vrnewg.com/90/html/index.html",
    },
    {
        id: "91",
        CompanyName: "鸿鑫钢丸科技有限公司",
        Link: "https://assets.vrnewg.com/91/html/index.html",
    },
    {
        id: "92",
        CompanyName: "无锡威力特船用锅炉有限公司",
        Link: "https://assets.vrnewg.com/92/html/index.html",
    },
    {
        id: "452",
        CompanyName: "中国科学院深圳先进技术研究院",
        Link: "https://assets.vrnewg.com/452/html/index.html",
    },
    {
        id: "94",
        CompanyName: "招商局集团有限公司",
        Link: "https://assets.vrnewg.com/94/html/index.html",
    },
    {
        id: "95",
        CompanyName: "广西南洋船舶工程有限公司",
        Link: "https://assets.vrnewg.com/95/html/index.html",
    },
    {
        id: "96",
        CompanyName: "康士伯船舶设备",
        Link: "https://assets.vrnewg.com/96/html/index.html",
    },
    {
        id: "97",
        CompanyName: "深圳赤湾石油基地后勤服务有限公司",
        Link: "https://assets.vrnewg.com/97/html/index.html",
    },
    {
        id: "98",
        CompanyName: "美国船级社（中国）有限公司",
        Link: "https://assets.vrnewg.com/98/html/index.html",
    },
    {
        id: "438",
        CompanyName: "河北斯力恩索具有限公司",
        Link: "https://assets.vrnewg.com/438/html/index.html",
    },
    {
        id: "100",
        CompanyName: "思尤考（上海）流体控制技术有限公司",
        Link: "https://assets.vrnewg.com/100/html/index.html",
    },
    {
        id: "101",
        CompanyName: "挪威船级社（中国）有限公司",
        Link: "https://assets.vrnewg.com/101/html/index.html",
    },
    {
        id: "102",
        CompanyName: "深圳市禾望电器股份有限公司",
        Link: "https://assets.vrnewg.com/102/html/index.html",
    },
    {
        id: "103",
        CompanyName: "东营新星船舶设备有限公司",
        Link: "https://assets.vrnewg.com/103/html/index.html",
    },
    {
        id: "104",
        CompanyName: "芜湖行健智能机器人有限公司",
        Link: "https://assets.vrnewg.com/104/html/index.html",
    },
    {
        id: "105",
        CompanyName: "依贝思标识科技（深圳）有限公司",
        Link: "https://assets.vrnewg.com/105/html/index.html",
    },
    {
        id: "106",
        CompanyName: "深圳市伟福特科技有限公司",
        Link: "https://assets.vrnewg.com/106/html/index.html",
    },
    {
        id: "107",
        CompanyName: "上海临港海洋高新技术产",
        Link: "https://assets.vrnewg.com/107/html/index.html",
    },
    {
        id: "108",
        CompanyName: "俊泰行（北京）通信技术有限公司",
        Link: "https://assets.vrnewg.com/108/html/index.html",
    },
    {
        id: "109",
        CompanyName: "海壮尼克冷却器有限公司",
        Link: "https://assets.vrnewg.com/109/html/index.html",
    },
    {
        id: "110",
        CompanyName: "深圳华襄科技有限公司",
        Link: "https://assets.vrnewg.com/110/html/index.html",
    },
    {
        id: "111",
        CompanyName: "深圳市中凯顺科技有限公司",
        Link: "https://assets.vrnewg.com/111/html/index.html",
    },
    {
        id: "112",
        CompanyName: "上海泷洋船舶科技有限公司",
        Link: "https://assets.vrnewg.com/112/html/index.html",
    },
    {
        id: "113",
        CompanyName: "深圳市微视光电科技有限公司",
        Link: "https://assets.vrnewg.com/113/html/index.html",
    },
    {
        id: "114",
        CompanyName: "广东银鹏动力设备有限公司",
        Link: "https://assets.vrnewg.com/114/html/index.html",
    },
    {
        id: "115",
        CompanyName: "游艇丝绸之路有限公司",
        Link: "https://assets.vrnewg.com/115/html/index.html",
    },
    {
        id: "116",
        CompanyName: "建湖县洋帆液压机械制造有限公司",
        Link: "https://assets.vrnewg.com/116/html/index.html",
    },
    {
        id: "117",
        CompanyName: "深圳市盛格纳电子有限公司",
        Link: "https://assets.vrnewg.com/117/html/index.html",
    },
    {
        id: "118",
        CompanyName: "西塞姆科技（深圳）有限公司",
        Link: "https://assets.vrnewg.com/118/html/index.html",
    },
    {
        id: "119",
        CompanyName: "吴川市鸿海绳网制造有限公司",
        Link: "https://assets.vrnewg.com/119/html/index.html",
    },
    {
        id: "120",
        CompanyName: "青岛船东协会",
        Link: "https://assets.vrnewg.com/120/html/index.html",
    },
    {
        id: "258",
        CompanyName: "青岛亨尔船舶用品有限公司",
        Link: "https://assets.vrnewg.com/258/html/index.html",
    },
    {
        id: "304",
        CompanyName: "东莞市平力五金有限公司",
        Link: "https://assets.vrnewg.com/304/html/index.html",
    },
    {
        id: "309",
        CompanyName: "佛山市顺德区浚楹五金电器有限公司",
        Link: "https://assets.vrnewg.com/309/html/index.html",
    },
    {
        id: "311",
        CompanyName: "广东凯力船艇股份有限公司",
        Link: "https://assets.vrnewg.com/311/html/index.html",
    },
    {
        id: "320",
        CompanyName: "和默化学（佛山）有限公司",
        Link: "https://assets.vrnewg.com/320/html/index.html",
    },
    {
        id: "321",
        CompanyName: "河北广浩管件有限公司",
        Link: "https://assets.vrnewg.com/321/html/index.html",
    },
    {
        id: "340",
        CompanyName: "青岛贝乐基游艇有限公司",
        Link: "https://assets.vrnewg.com/340/html/index.html",
    },
    {
        id: "347",
        CompanyName: "青岛瑞恩德机械设备有限公司",
        Link: "https://assets.vrnewg.com/347/html/index.html",
    },
    {
        id: "353",
        CompanyName: "山东省阳信海洋不锈钢制品有限公司",
        Link: "https://assets.vrnewg.com/353/html/index.html",
    },
    {
        id: "367",
        CompanyName: "深圳海洋船舶服务有限公司",
        Link: "https://assets.vrnewg.com/367/html/index.html",
    },
    {
        id: "382",
        CompanyName: "沈阳路博精润机电设备有限公司",
        Link: "https://assets.vrnewg.com/382/html/index.html",
    },
    {
        id: "387",
        CompanyName: "无锡宏盟海洋科技有限公司",
        Link: "https://assets.vrnewg.com/387/html/index.html",
    },
    {
        id: "390",
        CompanyName: "向荣游艇港供应链开发（深圳）有限公司",
        Link: "https://assets.vrnewg.com/390/html/index.html",
    },
    {
        id: "398",
        CompanyName: "浙江四兄绳业有限公司",
        Link: "https://assets.vrnewg.com/398/html/index.html",
    },
    {
        id: "413",
        CompanyName: "珠海云洲智能科技有限公司",
        Link: "https://assets.vrnewg.com/413/html/index.html",
    },
    {
        id: "435",
        CompanyName: "广州欧库贸易有限公司",
        Link: "https://assets.vrnewg.com/435/html/index.html",
    },
    {
        id: "438",
        CompanyName: "河北斯力恩索具有限公司",
        Link: "https://assets.vrnewg.com/438/html/index.html",
    },
    {
        id: "441",
        CompanyName: "上海决策者经济顾问股份有限公司",
        Link: "https://assets.vrnewg.com/441/html/index.html",
    },
    {
        id: "450",
        CompanyName: "深圳市小龙电器有限公司",
        Link: "https://assets.vrnewg.com/450/html/index.html",
    },
    {
        id: "452",
        CompanyName: "中国科学院深圳先进技术研究院",
        Link: "https://assets.vrnewg.com/452/html/index.html",
    },
    {
        id: "480",
        CompanyName: "必维船级社（中国）有限公司",
        Link: "https://assets.vrnewg.com/480/html/index.html",
    },
    {
        id: "484",
        CompanyName: "国家海洋环境预报中心（自然资源部海啸预警中心）",
        Link: "https://assets.vrnewg.com/484/html/index.html",
    },
    {
        id: "494",
        CompanyName: "锦洋不锈钢有限公司",
        Link: "https://assets.vrnewg.com/494/html/index.html",
    },
    {
        id: "504",
        CompanyName: "青岛邮轮游艇协会",
        Link: "https://assets.vrnewg.com/504/html/index.html",
    },
    {
        id: "512",
        CompanyName: "深圳华威近海船舶运输股份有限公司",
        Link: "https://assets.vrnewg.com/512/html/index.html",
    },
    {
        id: "519",
        CompanyName: "深圳市立鑫船舶服务有限公司",
        Link: "https://assets.vrnewg.com/519/html/index.html",
    },
    {
        id: "531",
        CompanyName: "中国交通建设集团有限公司",
        Link: "https://assets.vrnewg.com/531/html/index.html",
    },
    {
        id: "535",
        CompanyName: "珠海欣航船舶科技有限公司",
        Link: "https://assets.vrnewg.com/535/html/index.html",
    },
    {
        id: "556",
        CompanyName: "大连陆海科技股份有限公司",
        Link: "https://assets.vrnewg.com/556/html/index.html",
    },
    {
        id: "564",
        CompanyName: "广州荣德电子科技有限公司",
        Link: "https://assets.vrnewg.com/564/html/index.html",
    },
    {
        id: "578",
        CompanyName: "劳氏船级社（中国）有限公司",
        Link: "https://assets.vrnewg.com/578/html/index.html",
    },
    {
        id: "580",
        CompanyName: "玛姆特大件起重服务（天津）有限公司",
        Link: "https://assets.vrnewg.com/580/html/index.html",
    },
    {
        id: "582",
        CompanyName: "南通顺扬特种钢构有限公司",
        Link: "https://assets.vrnewg.com/582/html/index.html",
    },
    {
        id: "587",
        CompanyName: "上海会彬仪器有限公司",
        Link: "https://assets.vrnewg.com/587/html/index.html",
    },
    {
        id: "588",
        CompanyName: "上海纽皇商贸有限公司",
        Link: "https://assets.vrnewg.com/588/html/index.html",
    },
    {
        id: "611",
        CompanyName: "天津东一韬鸿智能控制装备有限公司",
        Link: "https://assets.vrnewg.com/611/html/index.html",
    },
    {
        id: "616",
        CompanyName: "粤海滚塑科技（中山）有限公司",
        Link: "https://assets.vrnewg.com/616/html/index.html",
    },
    {
        id: "619",
        CompanyName: "中山市凌达防锈润滑剂有限公司",
        Link: "https://assets.vrnewg.com/619/html/index.html",
    },
    {
        id: "620",
        CompanyName: "深圳市强镜起重设备有限公司",
        Link: "https://assets.vrnewg.com/620/html/index.html",
    },
    {
        id: "621",
        CompanyName: "阿艾司（天津）船舶入级服务有限公司",
        Link: "https://assets.vrnewg.com/621/html/index.html",
    },
    {
        id: "649",
        CompanyName: "深圳市海巽船舶技术有限公司",
        Link: "https://assets.vrnewg.com/649/html/index.html",
    },
    {
        id: "653",
        CompanyName: "深圳市维度数据科技股份有限公司",
        Link: "https://assets.vrnewg.com/653/html/index.html",
    },
    {
        id: "663",
        CompanyName: "昆山书豪仪器科技有限公司",
        Link: "https://assets.vrnewg.com/663/html/index.html",
    },
    {
        id: "683",
        CompanyName: "广州海明船舶维修服务有限公司",
        Link: "https://assets.vrnewg.com/683/html/index.html",
    },
    {
        id: "693",
        CompanyName: "深圳市行知行机器人技术有限公司",
        Link: "https://assets.vrnewg.com/693/html/index.html",
    },

    {
        id: 728,
        CompanyName: "深圳市东兴威电机有限公司",
        Link: "https://assets.vrnewg.com/728/html/index.html",
    },
    {
        id: 732,
        CompanyName: "深圳市大好科技有限公司",
        Link: "https://assets.vrnewg.com/732/html/index.html",
    },
    {
        id: 746,
        CompanyName: "武汉市华航集运物流有限责任公司 (长江航线联盟)",
        Link: "https://assets.vrnewg.com/746/html/index.html",
    },
    {
        id: 751,
        CompanyName: "深圳瑞联电机技术有限公司",
        Link: "https://assets.vrnewg.com/751/html/index.html",
    },
    {
        id: 753,
        CompanyName: "中山市国际货运代理协会",
        Link: "https://assets.vrnewg.com/753/html/index.html",
    },
    {
        id: 754,
        CompanyName: "GAL物流新加坡有限公司",
        Link: "https://assets.vrnewg.com/754/html/index.html",
    },
    {
        id: 756,
        CompanyName: "蓬莱金创精密铸造有限公司",
        Link: "https://assets.vrnewg.com/756/html/index.html",
    },
    {
        id: 757,
        CompanyName: "江苏省船舶工业行业协会",
        Link: "https://assets.vrnewg.com/757/html/index.html",
    },
    {
        id: 758,
        CompanyName: "天津滨海松昌国际物流（集团）有限公司",
        Link: "https://assets.vrnewg.com/758/html/index.html",
    },
    {
        id: 777,
        CompanyName: "上海智密技术工程研究所有限公司",
        Link: "https://assets.vrnewg.com/777/html/index.html",
    },
    {
        id: 782,
        CompanyName: "广州泉港国际货运代理有限公司",
        Link: "https://assets.vrnewg.com/782/html/index.html",
    },
    {
        id: 783,
        CompanyName: "非洲信盟控股（广州）有限公司",
        Link: "https://assets.vrnewg.com/783/html/index.html",
    },
    {
        id: 784,
        CompanyName: "天津市多式联运行业协会",
        Link: "https://assets.vrnewg.com/784/html/index.html",
    },
    {
        id: 785,
        CompanyName: "深圳市环世创源科技有限公司",
        Link: "https://assets.vrnewg.com/785/html/index.html",
    },
    {
        id: 817,
        CompanyName: "深圳市世汇物流科技有限公司（全球东北物流人联盟）",
        Link: "https://assets.vrnewg.com/817/html/index.html",
    },
    {
        id: 818,
        CompanyName: "广州市南沙区航运物流行业协会",
        Link: "https://assets.vrnewg.com/818/html/index.html",
    },
    {
        id: 819,
        CompanyName: "海鹏物流服务咨询（深圳）有限公司（香港富达会）",
        Link: "https://assets.vrnewg.com/819/html/index.html",
    },
    {
        id: 831,
        CompanyName: "广州誉德物流有限公司",
        Link: "https://assets.vrnewg.com/831/html/index.html",
    },
    {
        id: 832,
        CompanyName: "广州市柏仕途供应链有限公司",
        Link: "https://assets.vrnewg.com/832/html/index.html",
    },
    {
        id: 833,
        CompanyName: "广州市舟顺国际货运代理有限公司",
        Link: "https://assets.vrnewg.com/833/html/index.html",
    },
    {
        id: 834,
        CompanyName: "广西新锦航物流股份有限公司",
        Link: "https://assets.vrnewg.com/834/html/index.html",
    },
    {
        id: 840,
        CompanyName: "瓦锡兰维修服务（上海）有限公司",
        Link: "https://assets.vrnewg.com/840/html/index.html",
    },
    {
        id: 849,
        CompanyName: "珠海市游艇旅游协会",
        Link: "https://assets.vrnewg.com/849/html/index.html",
    },
    {
        id: 866,
        CompanyName: "中国船舶集团华南船机有限公司",
        Link: "https://assets.vrnewg.com/866/html/index.html",
    },
    {
        id: 875,
        CompanyName: "珠海博轮船艇设备有限公司",
        Link: "https://assets.vrnewg.com/875/html/index.html",
    },
    {
        id: 880,
        CompanyName: "深圳市科米纳新材料有限公司",
        Link: "https://assets.vrnewg.com/880/html/index.html",
    },
    {
        id: 881,
        CompanyName: "深圳市尼可达实业有限公司",
        Link: "https://assets.vrnewg.com/881/html/index.html",
    },
    {
        id: 883,
        CompanyName: "哈尔滨工程大学青岛船舶科技有限公司",
        Link: "https://assets.vrnewg.com/883/html/index.html",
    },
    {
        id: 884,
        CompanyName: "上海将海科技有限公司",
        Link: "https://assets.vrnewg.com/884/html/index.html",
    },
    {
        id: 891,
        CompanyName: "广东德立迅国际货运代理有限公司",
        Link: "https://assets.vrnewg.com/891/html/index.html",
    },
    {
        id: 893,
        CompanyName: "广州威林国际货运代理有限公司",
        Link: "https://assets.vrnewg.com/893/html/index.html",
    },
    {
        id: 894,
        CompanyName: "广东鸿硕供应链有限公司",
        Link: "https://assets.vrnewg.com/894/html/index.html",
    },
    {
        id: 896,
        CompanyName: "广州市天进国际货运代理有限公司",
        Link: "https://assets.vrnewg.com/896/html/index.html",
    },
    {
        id: 897,
        CompanyName: "广州富崴国际货运代理有限公司",
        Link: "https://assets.vrnewg.com/897/html/index.html",
    },
    {
        id: 899,
        CompanyName: "大卖仓（深圳）国际仓储物流有限公司",
        Link: "https://assets.vrnewg.com/899/html/index.html",
    },
    {
        id: 900,
        CompanyName: "海鹏物流服务咨询（深圳）有限公司",
        Link: "https://assets.vrnewg.com/900/html/index.html",
    },
    {
        id: 901,
        CompanyName: "富达网",
        Link: "https://assets.vrnewg.com/901/html/index.html",
    },
    {
        id: 902,
        CompanyName: "瀚翔物流（深圳）有限公司",
        Link: "https://assets.vrnewg.com/902/html/index.html",
    },
    {
        id: 911,
        CompanyName: "北京普思优创海洋技术有限公司",
        Link: "https://assets.vrnewg.com/911/html/index.html",
    },
    {
        id: 914,
        CompanyName: "大连合为船用电气有限公司",
        Link: "https://assets.vrnewg.com/914/html/index.html",
    },
    {
        id: 917,
        CompanyName: "广东侨骏实业发展有限公司",
        Link: "https://assets.vrnewg.com/917/html/index.html",
    },
    {
        id: 918,
        CompanyName: "广东深蓝水下特种设备科技有限公司",
        Link: "https://assets.vrnewg.com/918/html/index.html",
    },
    {
        id: 922,
        CompanyName: "广州航海学院",
        Link: "https://assets.vrnewg.com/922/html/index.html",
    },
    {
        id: 926,
        CompanyName: "广州三船商贸有限公司",
        Link: "https://assets.vrnewg.com/926/html/index.html",
    },
    {
        id: 928,
        CompanyName: "江苏云意驱动系统有限公司",
        Link: "https://assets.vrnewg.com/928/html/index.html",
    },
    {
        id: 931,
        CompanyName: "欧氏船舶检验(上海)有限公司",
        Link: "https://assets.vrnewg.com/931/html/index.html",
    },
    {
        id: 933,
        CompanyName: "青岛云潮未来科技有限公司",
        Link: "https://assets.vrnewg.com/933/html/index.html",
    },
    {
        id: 934,
        CompanyName: "厦门海洋高新城建设有限公司",
        Link: "https://assets.vrnewg.com/934/html/index.html",
    },
    {
        id: 936,
        CompanyName: "山东奔腾漆业股份有限公司",
        Link: "https://assets.vrnewg.com/936/html/index.html",
    },
    {
        id: 940,
        CompanyName: "上海羽仁照明电器有限公司",
        Link: "https://assets.vrnewg.com/940/html/index.html",
    },
    {
        id: 942,
        CompanyName: "深圳市艾博领先科技有限公司",
        Link: "https://assets.vrnewg.com/942/html/index.html",
    },
    {
        id: 943,
        CompanyName: "深圳市固德供应链管理有限公司",
        Link: "https://assets.vrnewg.com/943/html/index.html",
    },
    {
        id: 951,
        CompanyName: "天与君舟（广州）科技有限公司",
        Link: "https://assets.vrnewg.com/951/html/index.html",
    },
    {
        id: 953,
        CompanyName: "西安市秦港广告有限公司（钛业资讯理事会）",
        Link: "https://assets.vrnewg.com/953/html/index.html",
    },
    {
        id: 960,
        CompanyName: "中国(浙江)自由贸易试验区普陀综合事务中心",
        Link: "https://assets.vrnewg.com/960/html/index.html",
    },
    {
        id: 963,
        CompanyName: "中铁保险经纪有限责任公司深圳分公司",
        Link: "https://assets.vrnewg.com/963/html/index.html",
    },
    {
        id: 964,
        CompanyName: "舟泊通(舟山)港口服务有限公司",
        Link: "https://assets.vrnewg.com/964/html/index.html",
    },
];
