// 生态环保
// 合并到海洋旅游和文化
export default [
    {
        id: "183",
        Num: "2",
        CompanyName: "深圳市华普森电子有限公司",
        Link: "https://assets.vrnewg.com/183/html/index.html",
    },
    {
        id: "184",
        Num: "2",
        CompanyName: "浙江钰烯腐蚀控制股份有限公司",
        Link: "https://assets.vrnewg.com/184/html/index.html",
    },
    {
        id: "185",
        Num: "2",
        CompanyName: "深圳市格润海砂淡化技术有限公司",
        Link: "https://assets.vrnewg.com/185/html/index.html",
    },
    {
        id: "186",
        Num: "2",
        CompanyName: "北京金隅涂料有限责任公司",
        Link: "https://assets.vrnewg.com/186/html/index.html",
    },
    {
        id: "187",
        Num: "1",
        CompanyName: "中环海化（厦门）船舶智能涂料有限公司",
        Link: "https://assets.vrnewg.com/187/html/index.html",
    },
    {
        id: "188",
        Num: "1",
        CompanyName: "广州翔曦能源科技有限公司",
        Link: "https://assets.vrnewg.com/188/html/index.html",
    },
    {
        id: "189",
        Num: "2",
        CompanyName: "广州迈光电子科技股份有限公司",
        Link: "https://assets.vrnewg.com/189/html/index.html",
    },
    {
        id: "190",
        Num: "2",
        CompanyName: "深圳坤烁科技有限公司",
        Link: "https://assets.vrnewg.com/190/html/index.html",
    },
    {
        id: "191",
        Num: "2",
        CompanyName: "凡斯盾安全设备（天津）有限公司",
        Link: "https://assets.vrnewg.com/191/html/index.html",
    },
    {
        id: "192",
        Num: "4",
        CompanyName: "深圳潜行创新科技有限公司",
        Link: "https://assets.vrnewg.com/192/html/index.html",
    },
    {
        id: "193",
        Num: "2",
        CompanyName: "厦门斯坦道科学仪器股份有限公司",
        Link: "https://assets.vrnewg.com/193/html/index.html",
    },
    {
        id: "194",
        Num: "1",
        CompanyName: "深圳市广洁明水处理技术有限公司",
        Link: "https://assets.vrnewg.com/194/html/index.html",
    },
    {
        id: "195",
        Num: "1",
        CompanyName: "安提夫（厦门）海洋科技有限公司",
        Link: "https://assets.vrnewg.com/195/html/index.html",
    },
    {
        id: "196",
        Num: "2",
        CompanyName: "郑州仿弦新材料科技有限公司",
        Link: "https://assets.vrnewg.com/196/html/index.html",
    },
    {
        id: "197",
        Num: "1",
        CompanyName: "广东中加检测技术股份有限公司",
        Link: "https://assets.vrnewg.com/197/html/index.html",
    },
    {
        id: "198",
        Num: "5",
        CompanyName: "深圳市东部通用航空有限公司",
        Link: "https://assets.vrnewg.com/198/html/index.html",
    },
    {
        id: "199",
        Num: "2",
        CompanyName: "维萨拉（北京）测量技术有限公司",
        Link: "https://assets.vrnewg.com/199/html/index.html",
    },
    {
        id: "200",
        Num: "1",
        CompanyName: "成都顺和润博科技有限公司",
        Link: "https://assets.vrnewg.com/200/html/index.html",
    },
    {
        id: "201",
        Num: "4",
        CompanyName: "浙江西贝虎特种车辆股份有限公司",
        Link: "https://assets.vrnewg.com/201/html/index.html",
    },
    {
        id: "202",
        Num: "1",
        CompanyName: "中山市宏力称重设备有限公司",
        Link: "https://assets.vrnewg.com/202/html/index.html",
    },
    {
        id: "203",
        Num: "1",
        CompanyName: "衡水博顿科技有限公司",
        Link: "https://assets.vrnewg.com/203/html/index.html",
    },
    {
        id: "204",
        Num: "2",
        CompanyName: "深圳市国大长兴科技有限公司",
        Link: "https://assets.vrnewg.com/204/html/index.html",
    },
    {
        id: "205",
        Num: "1",
        CompanyName: "沈新聚氨酯制品有限公司",
        Link: "https://assets.vrnewg.com/205/html/index.html",
    },
    {
        id: "206",
        Num: "2",
        CompanyName: "深圳富宇达电子有限公司",
        Link: "https://assets.vrnewg.com/206/html/index.html",
    },
    {
        id: "207",
        Num: "4",
        CompanyName: "南阳市中通防爆电机电器有限公司",
        Link: "https://assets.vrnewg.com/207/html/index.html",
    },
    {
        id: "208",
        Num: "3",
        CompanyName: "博雅工道（北京）机器人科技有限公司",
        Link: "https://assets.vrnewg.com/208/html/index.html",
    },
    {
        id: "209",
        Num: "2",
        CompanyName: "广州和时通电子科技有限公司",
        Link: "https://assets.vrnewg.com/209/html/index.html",
    },
    {
        id: "210",
        Num: "2",
        CompanyName: "深圳华测",
        Link: "https://assets.vrnewg.com/210/html/index.html",
    },
    {
        id: "211",
        Num: "1",
        CompanyName: "深圳市博容能源有限公司",
        Link: "https://assets.vrnewg.com/211/html/index.html",
    },
    {
        id: "212",
        Num: "2",
        CompanyName: "南京安铁防腐技术有限公司",
        Link: "https://assets.vrnewg.com/212/html/index.html",
    },
    {
        id: "213",
        Num: "2",
        CompanyName: "山东东润仪表",
        Link: "https://assets.vrnewg.com/213/html/index.html",
    },
    {
        id: "214",
        Num: "2",
        CompanyName: "华荣科技股份有限公司",
        Link: "https://assets.vrnewg.com/214/html/index.html",
    },
    {
        id: "305",
        Num: "8",
        CompanyName: "东莞市擎弘电子科技有限公司",
        Link: "https://assets.vrnewg.com/305/html/index.html",
    },
    {
        id: "307",
        Num: "7",
        CompanyName: "东海航运保险股份有限公司",
        Link: "https://assets.vrnewg.com/307/html/index.html",
    },
    {
        id: "343",
        Num: "2",
        CompanyName: "青岛海洋地质研究所",
        Link: "https://assets.vrnewg.com/343/html/index.html",
    },
    {
        id: "349",
        Num: "7",
        CompanyName: "赛尔威游艇（江门）有限公司",
        Link: "https://assets.vrnewg.com/349/html/index.html",
    },
    {
        id: "354",
        Num: "7",
        CompanyName: "上海博科资讯股份有限公司",
        Link: "https://assets.vrnewg.com/354/html/index.html",
    },
    {
        id: "381",
        Num: "10",
        CompanyName: "深圳纵横四海航海俱乐部有限公司",
        Link: "https://assets.vrnewg.com/381/html/index.html",
    },
    {
        id: "436",
        Num: "8",
        CompanyName: "深圳华捷新材料有限公司",
        Link: "https://assets.vrnewg.com/436/html/index.html",
    },
    {
        id: "437",
        Num: "3",
        CompanyName: "南方海洋科学与工程广东省实验室（湛江）",
        Link: "https://assets.vrnewg.com/437/html/index.html",
    },
    {
        id: "439",
        Num: "2",
        CompanyName: "惠普斯液压缸制造（深圳）有限公司",
        Link: "https://assets.vrnewg.com/439/html/index.html",
    },
    {
        id: "444",
        Num: "14",
        CompanyName: "广东省海洋发展规划研究中心",
        Link: "https://assets.vrnewg.com/444/html/index.html",
    },
    {
        id: "445",
        Num: "17",
        CompanyName: "自然资源部第三海洋研究所",
        Link: "https://assets.vrnewg.com/445/html/index.html",
    },
    {
        id: "456",
        Num: "10",
        CompanyName: "中船贸易广州有限公司",
        Link: "https://assets.vrnewg.com/456/html/index.html",
    },
    {
        id: "459",
        Num: "17",
        CompanyName: "广东海洋协会",
        Link: "https://assets.vrnewg.com/459/html/index.html",
    },
    {
        id: "464",
        Num: "8",
        CompanyName: "帝泰凯贸易（上海）有限公司",
        Link: "https://assets.vrnewg.com/464/html/index.html",
    },
    {
        id: "465",
        Num: "10",
        CompanyName: "广西海洋局",
        Link: "https://assets.vrnewg.com/465/html/index.html",
    },
    {
        id: "509",
        Num: "7",
        CompanyName: "上海秀美模型有限公司",
        Link: "https://assets.vrnewg.com/509/html/index.html",
    },
    {
        id: "520",
        Num: "2",
        CompanyName: "深圳市赛为安全技术服务有限公司",
        Link: "https://assets.vrnewg.com/520/html/index.html",
    },
    {
        id: "521",
        Num: "7",
        CompanyName: "深圳市森泰机械工程有限公司",
        Link: "https://assets.vrnewg.com/521/html/index.html",
    },
    {
        id: "522",
        Num: "2",
        CompanyName: "深圳市斯菲特尔商贸有限公司",
        Link: "https://assets.vrnewg.com/522/html/index.html",
    },
    {
        id: "559",
        Num: "7",
        CompanyName: "广东精铟海洋工程股份有限公司",
        Link: "https://assets.vrnewg.com/559/html/index.html",
    },
    {
        id: "590",
        Num: "2",
        CompanyName: "深圳国际仲裁院海事仲裁中心",
        Link: "https://assets.vrnewg.com/590/html/index.html",
    },
    {
        id: "593",
        Num: "7",
        CompanyName: "深圳市发利机械设备结构有限公司",
        Link: "https://assets.vrnewg.com/593/html/index.html",
    },
    {
        id: "596",
        Num: "7",
        CompanyName: "深圳市好天气科技有限公司",
        Link: "https://assets.vrnewg.com/596/html/index.html",
    },
    {
        id: "601",
        Num: "2",
        CompanyName: "深圳市新宏新科技有限公司",
        Link: "https://assets.vrnewg.com/601/html/index.html",
    },
    {
        id: "623",
        Num: "7",
        CompanyName: "深圳市鹏程机电工程有限公司",
        Link: "https://assets.vrnewg.com/623/html/index.html",
    },

    {
        id: 703,
        CompanyName: "上海索尼克游艇码头有限公司",
        Link: "https://assets.vrnewg.com/703/html/index.html",
    },
    {
        id: 770,
        CompanyName: "中国大洋矿产资源研究开发协会深海科普工作委员会",
        Link: "https://assets.vrnewg.com/770/html/index.html",
    },
    {
        id: 773,
        CompanyName: "国家海洋博物馆",
        Link: "https://assets.vrnewg.com/773/html/index.html",
    },
    {
        id: 778,
        CompanyName: "光盒魔鱼（青岛）数字科技有限公司",
        Link: "https://assets.vrnewg.com/778/html/index.html",
    },
    {
        id: 904,
        CompanyName: "东莞市海升运动用品有限公司",
        Link: "https://assets.vrnewg.com/904/html/index.html",
    },
];
