import router from "@/router";
import BiologicalMedicine from "@/data/BiologicalMedicine.js"; // 海洋生物与医药
import EcosystemConservation from "@/data/EcosystemConservation.js"; // 海洋旅游和文化 2
import ElectronicInformation from "@/data/ElectronicInformation.js"; // 海洋电子信息
import HighEndDevices from "@/data/HighEndDevices.js"; // 海洋工程和环保
import MarineTourismCulture from "@/data/MarineTourismCulture.js"; // 海洋旅游与文化 1
import OceanShipping from "@/data/OceanShipping.js"; // 海洋港口与航运
import ResourceDevelopment from "@/data/ResourceDevelopment.js"; // 海洋资源开发

const List = unique([
    ...BiologicalMedicine,
    ...EcosystemConservation,
    ...ElectronicInformation,
    ...HighEndDevices,
    ...MarineTourismCulture,
    ...OceanShipping,
    ...ResourceDevelopment,
]);

function unique() {
    const list = arguments[0];
    const res = [];
    for (let i = 0; i < list.length; i++) {
        if (
            !res.find((item) => {
                return item.id === list[i].id;
            })
        ) {
            res.push(list[i]);
        }
    }
    return res;
}

export const jump = (path, target = "_blank") => {
    if (/^https?:\/\//.test(path)) {
        window.open(path, target);
    } else {
        router.push(path);
    }
};

export const cacheImage = (url) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            resolve();
        };
        image.onerror = () => {
            reject();
        };
        image.src = url;
    });
};

export const imgsPreloader = (imgs) => {
    return Promise.all(imgs.map(cacheImage));
};

export const isMobile = () => {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(ua);
};

export const search = (keyword) => {
    let searchList = [];
    if (keyword.trim().length > 0) {
        searchList = List.filter((e) => e.CompanyName.includes(keyword));
    }
    return searchList;
};
